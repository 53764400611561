import queryString from 'query-string'

const redirectToAuth = () => {
  const qs = queryString.stringify({
    redirectAfterLogin: window.location.pathname,
  })

  window.location.assign(`/acct/signin?${qs}`)
}

const redirectSignUp = () => {
  const qs = queryString.stringify({
    redirectAfterSignup: window.location.pathname,
  })

  window.location.assign(`/acct/signup?${qs}`)
}

export { redirectToAuth, redirectSignUp }
