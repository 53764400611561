import type { GetServerSideProps, NextPage } from 'next'
import { styled } from 'styled-components'

import { gsspMiddleware } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'

import StandardLayout from '@components/layouts/StandardLayout'

const Container = styled.div`
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin-right: 1rem;
  }
`
const NotFound: NextPage = () => {
  return (
    <StandardLayout>
      <Container>
        <h1>404</h1>
        <FormattedMessage id={'404.notFound'} defaultMessage={'404.notFound'} />
      </Container>
    </StandardLayout>
  )
}

/* pages/_error.js is only used in production. In development you’ll get an error with the
 * call stack to know where the error originated from.
 */

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return gsspMiddleware({
    ctx,
    prefetchQueries: [],
  })
}

export default NotFound
