type Query = {
  [key: string]: string | string[] | undefined
}

const demandParam = (query: Query | undefined, param: string): string => {
  if (typeof query === 'undefined') {
    throw new Error('`query` is undefined')
  }

  const value = query[param]

  if (typeof value === 'string') {
    return query[param] as string
  } else if (typeof value === 'object') {
    throw new Error(`Multiple values passed for param "${param}"`)
  } else {
    throw new Error(`No value passed for param "${param}"`)
  }
}

export default demandParam
