import {
  buildAxiosRequestConfig,
  getStorefrontsClient,
  makeOptionallyAuthenticatedQuery,
} from '@b-stock/bstock-next'
import type {
  StorefrontsApiFindAllStoreFrontsRequest,
  StoreFrontDto,
} from '@b-stock/listing-api-client'

export const getStorefront = async (
  accessToken: string | null,
  query: StorefrontsApiFindAllStoreFrontsRequest
): Promise<StoreFrontDto | null> => {
  const client = getStorefrontsClient()
  const response = await client.findAllStoreFronts(
    query,
    buildAxiosRequestConfig({ accessToken })
  )
  const {
    data: { storeFronts },
  } = response

  return storeFronts.length ? storeFronts[0] : null
}

const storefrontQuery = makeOptionallyAuthenticatedQuery(
  'storefronts',
  getStorefront
)
export default storefrontQuery
