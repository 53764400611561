import type { ReactNode } from 'react'

import { styled } from 'styled-components'

import { Link, FormattedMessage } from '@b-stock/bstock-react'
import type { DocumentDto, ListingDto } from '@b-stock/listing-api-client'
import { DocumentDtoDocTypeEnum } from '@b-stock/listing-api-client'

import { DetailsValue, TableContainer, Title } from '../shared'

export const InfoSection = styled.div`
  position: relative;
  margin-bottom: 5rem;
`

type PoliciesProps = {
  documents: ListingDto['documents']
  documentType: DocumentDtoDocTypeEnum
}

type DocumentLinkProps = {
  documents?: DocumentDto[]
  documentType: DocumentDtoDocTypeEnum
  children?: React.ReactNode
}

export const DocumentLink = ({
  documents,
  documentType,
  children,
}: DocumentLinkProps) => {
  const doc = documents?.find(({ docType }) => docType === documentType)

  return (
    <Link target="_blank" href={doc?.url ?? '#'}>
      {children}
    </Link>
  )
}

const Policies = ({ documents }: PoliciesProps) => (
  <>
    <TableContainer>
      <Title>
        <FormattedMessage id="Auction.title.policies" />
      </Title>
      <div>
        <InfoSection>
          <DetailsValue>
            <FormattedMessage
              id="Auction.body.policies"
              values={{
                link: (content: ReactNode) => (
                  <DocumentLink
                    documents={documents}
                    documentType={DocumentDtoDocTypeEnum.TermsOfService}
                  >
                    {content}
                  </DocumentLink>
                ),
              }}
            />
          </DetailsValue>
        </InfoSection>
      </div>
    </TableContainer>
  </>
)

export default Policies
